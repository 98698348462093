









































































import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterItem } from '../model/router-item';

@Component
export default class Social extends Vue {
  public socialItems: RouterItem[] = [
    {
      title: 'LinkedIn',
      route: 'https://www.linkedin.com/in/nicolasloots/',
      icon: 'mdi-linkedin',
    },
    {
      title: 'GitHub',
      route: 'https://github.com/GXGOW',
      icon: 'mdi-github',
    },
    {
      title: 'GitLab',
      route: 'https://gitlab.com/GXGOW',
      icon: 'mdi-gitlab',
    },
  ];
}
